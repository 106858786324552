import React from "react"
import './index.css'
import bumblebee from '../../static/Bumblebee.svg'



export default function Home() {
  return (
    <>
      <title>Bumblebee</title>
      <div className='header'>
        <div className='brand'>
            <img src={bumblebee} height='72' width='72' alt='Bumblebee icon'/>
            <div className='brand-name'>
              <h1>Bumblebee</h1>
              <p>Design &amp; Development</p>
            </div>
        </div>
      </div>

      <div className="call-to-action">
        <h2>COMING SOON</h2>
        <p>A new way to improve your small business' web presence</p>
      </div>
    </>
  )
}



